<div class="campaignPricing">
  <div class="col-12 mx-0 px-0 mb-2 justify-content-center">
    <div class="campaignHeader col-12 text-center">Campaign pricing</div>
    <div class="p-3">
      <mat-form-field
        appearance="outline"
        class="col-4 mx-2 px-0 my-auto pt-4 pl-3"
      >
        <mat-label>Currency</mat-label>
        <mat-select [(ngModel)]="currency">
          <mat-option value="CAD">CAD</mat-option>
          <mat-option value="USD">USD</mat-option>
          <mat-option value="AUD">AUD</mat-option>
          <mat-option value="BRL">BRL</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-5 mx-2 px-0 my-auto pt-4 pl-3">
        <input
          matInput
          autocomplete="off"
          type="number"
          min="0"
          max="100000"
          [(ngModel)]="price"
          (keyup)="onChange()"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Payment options</mat-label>
        <mat-select [(ngModel)]="selectedPaymentType">
          <mat-option
            matTooltipPosition="above"
            [matTooltip]="option.toolTip"
            *ngFor="let option of paymentOptions"
            value="{{ option.type }}"
            (click)="option.callback()"
          >
            {{ option.type }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="mb-3"
      *ngIf="errorMsg"
    >
      <h4 class="warn">{{ errorMsg }}</h4>
    </div>
    <div class="text-center confirmBtn">
      <app-simple-button
        text="CONFIRM"
        [smaller]="true"
        (click)="onConfirm()"
      ></app-simple-button>
    </div>
  </div>
</div>
